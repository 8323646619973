
import { computed, defineComponent, onMounted, ref } from "vue";
import { ApiSettlementCash } from "@/core/api";
import mixin from "@/mixins";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import { CommonHtmlType } from "@/core/directive/type/common";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import {
  commonChangeDefaultDate,
  formatDate,
  getCurrency,
  modalShowListener,
} from "@/core/directive/function/common";
import { useRouter } from "vue-router";
import { InternalCashPoolStatus } from "@/core/directive/type/settlementCash";
import { getInternalCashPoolStatus } from "@/core/directive/function/settlementCash";
import { PaymentGateway } from "@/core/directive/type/transactionReport";

export default defineComponent({
  name: "settlement-cash-internal-cash-pool-update-item",
  emits: ["update-list", "reset-form"],
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();
    const {
      showServerErrorMsg,
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
    } = mixin();

    const loading = ref(false);

    const updateCashItemRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref({
      paid_time: "",
      external_transaction_platform: "",
      paid_amount: 0,
      paid_currency: "CNY",
      pay_bank_account_no: "",
      pay_bank_account_name: "",
      opening_bank: "",
      external_transaction_id: "",
      prepayment_balance: 0,
    });

    const options = ref({
      currency: [
        {
          label: "EUR",
          value: "EUR",
        },
        {
          label: "CNY",
          value: "CNY",
        },
      ],
    });

    const rules = ref({
      external_transaction_platform: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      paid_time: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      paid_currency: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      paid_amount: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      pay_bank_account_no: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      pay_bank_account_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      opening_bank: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      external_transaction_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      prepayment_balance: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    // const getTaggingData = async () => {
    //   const { data } = await ApiBase.getTaggingData({
    //     short_key: ["sku_inventory_type"],
    //   });
    //   if (data.code == 0) {
    //     // options.value.inventory_type = data.data.sku_inventory_type.items;
    //   }
    // };

    const getAllCurrency = computed(() => {
      return getCurrency.value(
        props.option.itemData.paid_currency,
        props.option.itemData.currency
      );
    });

    const isEdit = computed(() => {
      return props.option.itemData.status === InternalCashPoolStatus.Unpaid;
    });

    const paymentMethodChange = (e) => {
      // for (let key in formData.value) {
      //   if (key !== "external_transaction_platform") {
      //     if (key === "paid_amount" || key === "prepayment_balance") {
      //       formData.value[key] = 0;
      //     } else if (key === "paid_currency") {
      //       formData.value[key] = "CNY";
      //     } else {
      //       formData.value[key] = "";
      //     }
      //   }
      // }
      formData.value.pay_bank_account_no = "";
      formData.value.pay_bank_account_name = "";
      formData.value.opening_bank = "";
      formData.value.external_transaction_id = "";
    };

    const submitValidate = (callback) => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          Swal.fire({
            text: t("settlementCash.submitTip"),
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: t("common.yesClose"),
            cancelButtonText: t("common.noReturn"),
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger",
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              loading.value = true;
              const params = {
                id: props.option.itemData.id,
                ...formData.value,
              };
              callback(params);
            }
          });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const cashRefundPay = () => {
      submitValidate(async (params) => {
        const { data } = await ApiSettlementCash.cashRefundPay(params);
        loading.value = false;
        if (data.code === 0) {
          showFormSubmitSuccessMsg(() => {
            hideModal(updateCashItemRef.value);
            emit("update-list");
          });
        } else {
          showServerErrorMsg(data);
        }
      });
    };

    const submit = () => {
      submitValidate(async (params) => {
        const { data } = await ApiSettlementCash.cashPayment(params);
        loading.value = false;
        if (data.code === 0) {
          showFormSubmitSuccessMsg(() => {
            hideModal(updateCashItemRef.value);
            emit("update-list");
          });
        } else {
          showServerErrorMsg(data);
        }
      });
    };

    const getFormInfo = async () => {
      for (let key in formData.value) {
        if (props.option.itemData[key]) {
          formData.value[key] = props.option.itemData[key];
        }
      }
      formData.value.paid_currency = getAllCurrency.value;
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const handleDiscard = () => {
      resetForm();
      hideModal(updateCashItemRef.value);
    };

    onMounted(() => {
      modalShowListener(updateCashItemRef.value, () => {
        setTimeout(() => {
          getFormInfo();
        }, 10);
      });
      modalHideListener(updateCashItemRef.value, () => {
        resetForm();
      });
      //   getTaggingData();
    });

    return {
      t,
      formatDate,
      commonChangeDefaultDate,
      getInternalCashPoolStatus,
      InternalCashPoolStatus,
      PaymentGateway,
      props,
      updateCashItemRef,
      formRef,
      loading,
      formData,
      rules,
      options,
      isEdit,
      getAllCurrency,
      paymentMethodChange,
      handleDiscard,
      cashRefundPay,
      submit,
    };
  },
});
